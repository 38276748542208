import * as React from "react"
import { FaTwitter, FaYoutube } from 'react-icons/fa';
import { ArrowNarrowLeftIcon, MailIcon } from '@heroicons/react/solid'

import Layout from "../components/layout"
import Seo from "../components/seo"
import { motion } from "framer-motion";
import { Link } from "gatsby";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const post = {
  date: '05/08/2021',
  readingTime: '2mins',
  author: {
    name: 'Matt Evans',
    twitter_url: 'https://twitter.com/motioncoder',
    image: 'https://pbs.twimg.com/profile_images/1372188526926774275/DcYHLkc1_400x400.jpg'
  },
  category: {
    name: 'Announcements',
    href: '#',
    colorClass: 'red'
  },
  image: 'https://i.ytimg.com/vi/QXw1DYm6qx4/maxresdefault.jpg',
}

const SinglePost = () => (
  <Layout>
    <Seo title="Single Post" />
    <section className="bg-gray-100">
      <div className="relative px-6 sm:px-8 py-16">
        <div className="max-w-3xl mx-auto">
          <motion.h1
            initial={{ y: -30, opacity: 0 }}
            animate={{ y: '0', opacity: 1 }}
            transition={{ ease: 'easeOut', duration: 0.5 }}
            className="text-center text-4xl mb-6 leading-snug">Create realistic screen mockups using Adobe After Effects</motion.h1>

          <motion.div
            initial={{ y: -25, opacity: 0 }}
            animate={{ y: '0', opacity: 1 }}
            transition={{ ease: 'easeOut', duration: 0.5 }}
            className="flex justify-center items-center">
            <div className="flex-shrink-0">
              <span className="sr-only">{post.author.name}</span>
              <img className="h-12 w-12 rounded-full border-2 border-white shadow" src={post.author.image} alt="" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-gray-900">
                <a href={post.author.twitter} className="hover:underline">
                  {post.author.name}
                </a>
              </p>
              <div className="flex space-x-1 text-sm">
                <time className="text-gray-400" dateTime={post.datetime}>{post.date}</time>
                <span aria-hidden="true">&middot;</span>
                <span className="text-gray-500">{post.readingTime} read</span>
              </div>
            </div>
          </motion.div>
        </div>

        <motion.div
          initial={{ y: 40, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ ease: 'easeOut', duration: 0.5 }}
          className="mt-10 text-lg max-w-prose mx-auto">
          <div className={`h-2 w-full rounded-t-md bg-gradient-to-r from-${post.category.colorClass}-400 to-${post.category.colorClass}-600`}></div>
          <div className="bg-white px-8 md:px-16 pt-10 pb-16 rounded-b-xl shadow-lg max-w-full">

            <div className="flex items-center justify-between mb-3.5">
              <a className={classNames('text-' + post.category.colorClass + '-500 ' + 'bg-' + post.category.colorClass + '-50 ' + 'hover:bg-' + post.category.colorClass + '-100', 'inline-block text-sm font-medium rounded-full transition-all duration-350 px-3.5 py-1')} href={post.category.href}>
                {post.category.name}
              </a>
              <Link to="/blog" className="group flex items-center text-sm text-gray-400 hover:text-gray-500 transition-colors duration-150"><ArrowNarrowLeftIcon className="h-4 w-4 text-gray-400 transition-colors duration-150 group-hover:text-gray-500 mr-1.5" />Back to Blog</Link>
            </div>

            <img className="w-full rounded-lg mb-7" src={post.image} />

            <div className="prose">
              <p>
                Faucibus commodo massa rhoncus, volutpat. <strong>Dignissim</strong> sed <strong>eget risus enim</strong>.
                Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra
                tellus varius sit neque erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim.{' '}
                <a href="#">Mattis mauris semper</a> sed amet vitae sed turpis id.
              </p>
              <ul>
                <li>Quis elit egestas venenatis mattis dignissim.</li>
                <li>Cras cras lobortis vitae vivamus ultricies facilisis tempus.</li>
                <li>Orci in sit morbi dignissim metus diam arcu pretium.</li>
              </ul>
              <p>
                Quis semper vulputate aliquam venenatis egestas sagittis quisque orci. Donec commodo sit viverra aliquam
                porttitor ultrices gravida eu. Tincidunt leo, elementum mattis elementum ut nisl, justo, amet, mattis. Nunc
                purus, diam commodo tincidunt turpis. Amet, duis sed elit interdum dignissim.
              </p>
              <h2>From beginner to expert in 30 days</h2>
              <p>
                Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam consequat in. Convallis arcu ipsum urna nibh.
                Pharetra, euismod vitae interdum mauris enim, consequat vulputate nibh. Maecenas pellentesque id sed tellus
                mauris, ultrices mauris. Tincidunt enim cursus ridiculus mi. Pellentesque nam sed nullam sed diam turpis
                ipsum eu a sed convallis diam.
              </p>
              <blockquote>
                <p>
                  Sagittis scelerisque nulla cursus in enim consectetur quam. Dictum urna sed consectetur neque tristique
                  pellentesque. Blandit amet, sed aenean erat arcu morbi.
                </p>
              </blockquote>
              <p>
                Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae
                sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit.
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </section>

  </Layout>
)

export default SinglePost;
